import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import ButtonLink from "./ButtonLink";

function GuitarZone() {
  // Types for Link doesn't allow arbitrary props.
  const LinkComponent = Link as any;

  return (
    <div className="center-container">
      <Container>
        <h1>CHÀO MỪNG ĐẾN VỚI GUITAR ZONE!!!</h1>
        <LinkComponent to="/" variant="danger" component={ButtonLink}>
          Về nhà của bạn
        </LinkComponent>
      </Container>
    </div>
  );
}

export default GuitarZone;
