import { BrowserRouter, Switch, Route } from "react-router-dom";
import NhaCuaBan from "./NhaCuaBan";
import GuitarZone from "./GuitarZone";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/GuitarZone">
          <GuitarZone />
        </Route>
        <Route path="/NhaCuaBan">
          <NhaCuaBan />
        </Route>
        <Route path="/">
          <NhaCuaBan />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
