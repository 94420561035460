import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import ButtonLink from "./ButtonLink";

function NhaCuaBan() {
  return (
    <div className="center-container">
      <Container>
        <h1>CHÀO MỪNG BẠN TRỞ VỀ NHÀ!!!</h1>
        <Link to="/GuitarZone" component={ButtonLink}>
          Đến Guitar Zone nghe nhạc
        </Link>
      </Container>
    </div>
  );
}

export default NhaCuaBan;
